import React from 'react';

/*
 * Rather than hide this, a better way would
 * be a tooltip and even in the unselected lang.
 * But, it's not recommended to call the API
 * directly, so consider removing entirely also.
 */

const Logo = () => {
  return (
    <svg viewBox="0 0 200 28.7">
<g>
	<path d="M121.3,2.5c1.7-0.1,3.3-0.2,5-0.2c1,0,2.1-0.2,3.1,0c0.2,0.1,0.6,0,0.7,0.4s-0.2,0.5-0.5,0.6c-0.5,0.2-1,0.4-1.5,0.4
		c-1.4,0-2.7,0.3-4.1,0.4c-0.8,0.1-1.6,0.1-2.4,0c-0.2,0-0.4,0-0.6,0.1c-1.1,0.4-2.2,0-3.3,0.3c-1.2,0.3-2.6,0-3.8,0.5
		c-0.4,0.2-0.9,0.1-1.4,0.1c-1.3,0-2.4,0.2-3.7,0.7c-1,0.3-2.1,0.5-3.1,0.6c-2.6,0.2-5,1.2-7.4,2.1c-1.7,0.7-3.4,1.3-5,2.3
		c-0.7,0.5-1.6,0.1-2-0.8c-0.3-0.7,0-1.5,0.8-1.8c1.7-0.6,3.4-1.4,5.1-2c1.7-0.7,3.5-1.3,5.3-1.7c2.4-0.5,4.8-1.1,7.2-1.3
		C113.6,2.9,117.5,2.6,121.3,2.5z"/>
	<path d="M61,12.4c0.2,0.2,0.5,0.2,0.7,0.2c0.4,0,0.8,0.1,1,0.5c0.1,0.5-0.2,0.8-0.5,1c-0.6,0.3-1.2,0.6-1.9,0.8
		c-1.3,0.5-2.3,1.4-3,2.5c-0.5,0.8-1.1,1.7-1.6,2.5c-0.5,0.8-1.2,1.4-1.6,2.3c-0.5,1.2-1.2,2.3-1.2,3.6c0,0.3-0.2,0.5-0.2,0.7
		c-0.4,0.2-0.2-0.7-0.7-0.2c-0.1,0.1-0.2,0.7-0.7,0.4c-0.4-0.3-0.7-0.7-0.8-1.2c-0.2-1.1,0.2-1.9,0.6-2.9c0.7-1.4,1.2-2.8,2.3-4
		c0.3-0.4,0.6-0.7,1-1c-0.3-0.3-0.5-0.1-0.8,0c-0.7,0.4-1.7,0-2.2-0.7c-0.4-0.5-0.2-1,0.2-1.4c0.3-0.4,0.9-0.4,1.3-0.6
		c1.1-0.4,2.2-0.8,3.4-1.2c0.5-0.2,0.9-0.5,1.1-1c1.1-1.8,2.1-3.7,3.2-5.5c0.3-0.5,1.7-0.5,2,0c0.4,0.5,0.8,1.2,0.3,1.9
		C62.2,10.4,61.9,11.6,61,12.4z"/>
	<path d="M161.4,20.4c1.3-0.9,2.8-1.6,3.7-2.9c0.8-1.1,1.9-1,2.6-0.4c0.6,0.5,0.7,1.3,0.2,2c-0.7,1.2-1.9,1.9-3,2.7
		c-0.4,0.3-1.1,0.5-1,1c0.2,0.6,0.9,0.3,1.4,0.4c0.6,0,1.1,0,1.7,0c0.5,0.1,0.4,0.7,0.6,1c0.2,0.2-0.1,0.7-0.5,0.7
		c-1.1,0.2-2.1,0.7-3.2,0.7c-1.2,0.1-2.6,0-3.6-0.9c-0.3-0.2-0.6-0.3-0.8,0c-0.5,0.6-1.4,1-1.9,1.6c-0.4,0.5-1.2,0.4-1.6,0.1
		c-0.5-0.3-0.9-0.9-0.7-1.5c0.1-0.3,0.1-0.7,0.4-1.1c1.3-1.7,2.3-3.7,3.4-5.6c0.1-0.1,0.2-0.2,0.2-0.4c0.5-1.2,0.7-1.2,1.9-0.9
		c0.7,0.2,1,0.9,0.8,1.8c0,0.2-0.1,0.5-0.2,0.8C161.6,20,161.5,20.2,161.4,20.4z"/>
	<path d="M140.1,21.8c-0.5,0-0.9,0-1.3,0c-1-0.1-1.4,0.7-1.9,1.4c-0.2,0.2,0.1,0.5,0.3,0.3c0.8-0.4,1.6-0.1,2.4-0.3
		c0.5-0.1,1-0.1,1.2,0.4c0.2,0.5-0.2,0.9-0.6,1.1c-1.4,0.8-2.9,1.5-4.5,2c-1.6,0.5-2.5,0-3.3-1.4c-0.5-1,0.3-1.5,0.7-2.1
		c0.9-1.2,1.7-2.3,2.4-3.6c0.5-0.7,0.9-1.5,0.4-2.5c-0.3-0.6,0.4-1.2,0.9-1.3c2.3-0.5,4.6-1.1,7-1c0.5,0,1.1,0,1.4,0.5
		c0.5,0.5,0.4,1-0.2,1.4c-0.9,0.5-1.9,0.7-2.9,0.9c-0.8,0.1-1.6,0.3-2.4,0.5c-0.3,0.1-0.7,0.2-0.7,0.7c0.1,0.5,0.5,0.3,0.8,0.3
		c0.6,0,1.2,0,1.7,0.1c0.5,0,0.9,0.4,1,0.9c0.1,0.5,0,1-0.5,1.2C141.5,21.8,140.7,21.8,140.1,21.8z"/>
	<path d="M154.1,15.6c0.7,0.1,0.7,0.1,1.9,1.8c-1,0.6-2,0.8-3.1,0.7c-0.8,0-1,1-1.4,1.5c-1,1.4-1.7,3-2.5,4.5
		c-0.3,0.6-0.1,1.2-0.4,1.8c-0.2,0.3-0.5,0.9-0.6,0.8c-0.6-0.4-1.7,0.5-1.9-1c-0.1-1,0-1.8,0.5-2.8c0.4-0.7,0.4-1.6,0.9-2.3
		c0.3-0.6,0.7-1.1,1-1.7c0.3-0.5,0.2-0.8-0.3-0.9c-0.7-0.1-1.1-0.5-1.4-1.1c-0.2-0.7,0.1-1.3,0.9-1.3c0.9,0,1.7-0.3,2.5-0.2
		c0.4,0,0.6-0.2,0.7-0.6c1.2-2.1,2.4-4.2,3.7-6.3c0.5-0.8,1.4-1.1,2.1-0.6c1,0.6,1,1.4,0.5,2.2c-0.9,1.7-2.2,3.1-3,4.8
		C154.1,15.4,153.9,15.5,154.1,15.6z"/>
	<path d="M122,23.2c1.7-0.7,3.1-1.3,4.2-2.5c0.4-0.5,1.1-0.7,1.6-1.2c0.9-1.1,1.8-2.1,2.1-3.5c0.1-0.3,0.3-0.5,0.4-0.8
		c0.1-0.5,0.2-0.8,0.8-0.8c0.6,0.1,0.9,0.5,1,1c0.1,1.3-0.4,2.4-0.9,3.5c-1,2-2.7,3.3-4.4,4.6c-1.2,0.9-2.4,1.8-3.7,2.6
		c-1,0.6-2.2,0.8-3.2-0.2c-0.8-0.8-1.4-2-0.8-3.4c0.7-1.6,1.3-3.2,1.9-4.9c0-0.1,0.1-0.3,0.2-0.4c0.2-1,0.7-1.5,1.6-1.3
		c1,0.2,1.6,1.2,1.2,2.2c-0.5,1.4-1,2.7-1.6,4.1C122.3,22.5,122.2,22.7,122,23.2z"/>
	<path d="M36.7,22.9c-0.2-0.1-0.5,0-0.5,0.1c-0.8,1.2-2.1,1.2-3.2,1.4c-0.4,0.1-0.8,0-1-0.2c-0.7-0.7-1-0.1-1.2,0.3
		c-0.3,0.7-0.7,1.4-1.3,1.9c-0.4,0.3-1,0.4-1.2,0.1c-0.3-0.5-1-0.7-0.8-1.4c0.1-0.6,0.2-1.1,0.9-1.4c0.2-0.1,0.3-0.4,0.4-0.6
		c1-1.8,2-3.6,2.6-5.5c0.2-0.5,1.2-0.8,1.8-0.4c0.7,0.5,1.1,1.4,0.8,2.3c-0.1,0.4-0.2,0.8-0.3,1.2c-0.4,1,0.2,0.8,0.7,0.6
		c1.7-0.7,3.4-1.3,4.2-3.1c0.3-0.6,1.3-0.9,1.9-0.6c0.7,0.4,1.2,1.4,0.7,2.2c-0.6,1-1.2,2-1.5,3.2c-0.1,0.7-0.6,1.2-0.5,2
		c0,0.2-0.1,0.5-0.3,0.6c-0.4-0.5-0.7,0.6-1.4-0.1C36.6,24.6,37,23.8,36.7,22.9z"/>
	<path d="M187.1,19.3c0.2,1.1-0.7,1.9-0.9,2.9c-0.1,0.4-0.4,0.9-0.1,1.2c0.3,0.3,0.8,0,1.2-0.2c3.8-2,7.5-4.2,10.6-7.1
		c0.3-0.3,0.7-0.5,1-0.7c0.2-0.1,0.4-0.4,0.7-0.1c0.3,0.2,0.3,0.5,0.3,0.8c-0.1,0.9-0.7,1.6-1.4,2.1c-2,1.6-3.9,3.4-6.1,4.8
		c-1.5,1-2.9,2.1-4.4,3c-1,0.6-2.2,1.1-3.4,0.9c-1.2-0.2-2.1-1.3-1.9-2.5c0.4-2.1,1-4.1,1.9-6.1c0.2-0.6,0.9-0.4,1.3-0.3
		c0.5,0.1,1.1,0.2,1.1,0.9C187.1,19,187.1,19.1,187.1,19.3z"/>
	<path d="M66.1,15.4c1,0,1.8,0.7,1.7,1.7c0,1,0.5,1.9,0.6,2.9c0,0.5,0.7,0.2,1,0.4c0.7,0.5,0.9,1,0.2,1.7c-0.1,0.1-0.2,0.2-0.4,0.1
		c-1.2-0.1-1.7,0.7-1.9,1.6c-0.2,0.6-0.7,0.9-1,1.4c-0.2,0.5-0.7,0-1.1-0.2c-0.3-0.2-0.2-0.9-0.9-0.7c0,0-0.1-0.5,0-0.8
		c0.1-0.3,0.5-0.6,0.1-0.8c-0.4-0.2-0.8,0.1-1.2,0.4c-1.2,1.1-2.1,2.5-3.4,3.6c-0.8,0.6-1.4,0.5-2.1-0.5c-0.4-0.6-0.2-1.3,0.4-1.7
		c1-0.6,1.5-1.7,2.4-2.3c0.2-0.2,0-0.4,0-0.5c-0.2-0.8,0.2-1.2,1-1.3c0.2,0,0.5,0,0.6-0.3c0.8-1.3,2-2.3,2.7-3.7
		C65.2,15.7,65.5,15.3,66.1,15.4z"/>
	<path d="M108.1,9.1c0.1,1.1-0.7,1.7-1.2,2.4c-1.8,2.7-3.8,5.3-5.6,8.1c-0.7,1.1-1.1,2.3-1.7,3.4c-0.4,0.7-0.3,1.4-0.5,2.1
		c-0.1,0.4,0,1-0.6,1.1c-0.6,0.1-1.1,0-1.4-0.6c-0.3-0.5-0.3-1-0.3-1.7c0-2.1,1.2-3.7,2.2-5.3c1.9-3,3.7-6,5.6-9
		c0.4-0.6,0.8-1.1,1.1-1.7c0.3-0.7,1.1-0.3,1.5-0.2C107.7,7.8,108.2,8.4,108.1,9.1z"/>
	<path d="M43,24.5c0.1-1.2,0.9-2.4,1.7-3.7c0.2-0.4,0.4-0.8,0.7-1.2c0.5-0.5,1-0.6,1.6-0.3c0.7,0.3,1.2,1.2,0.9,1.7
		c-1,1.4-1,3.3-1.7,4.8c-0.5,0.8-1.2,1-2,1C43.7,26.8,43,25.6,43,24.5z"/>
	<path d="M24.9,19c1.1,0,1.8,1.1,1.2,2.2c-0.7,1.7-1.7,3.4-2.8,4.9c-0.3,0.4-0.9,0.7-1.4,0.4c-0.8-0.5-1.2-1.4-0.8-2
		c0.9-1.6,1.7-3.1,2.6-4.7C24,19.3,24.3,19,24.9,19z"/>
	<path d="M24,14.8c0.9-0.2,1.7,0.3,2.5,1c0.4,0.4,0.4,0.7-0.1,1.1C26,17,25.6,17,25.3,17c-0.9,0.2-1.8,0.2-2.7,0.3
		c-0.8,0-1-0.6-1.3-1.2c-0.2-0.5,0-0.8,0.3-1c0.5-0.4,1.2-0.1,1.7-0.4C23.4,14.8,23.7,14.8,24,14.8z"/>
	<path d="M46.3,16.7c-1,0-2,0-2.7-0.8c-0.5-0.5-0.5-1.1-0.2-1.7c0.3-0.6,0.9-0.3,1.3-0.2c0.7,0.2,1.3,0.5,1.9,0.6
		c0.7,0.2,0.9,1,1.3,1.4c0.2,0.2-0.1,0.6-0.5,0.6C47,16.7,46.7,16.7,46.3,16.7z"/>
	<path d="M17.6,6.3c0.5-1.1,1.4-2,2-3.1c0.4-0.8,1.1-1.7,0-2.7c-0.7-0.6-1.4-0.7-1.9,0.1c-0.2,0.3-0.3,0.7-0.5,1
		c-1.3,1.7-2,3.7-3,5.6c-0.2,0.4-0.4,0.9-0.9,0.2c-0.3-0.5-0.7-0.8-1-1.3C11.1,4.6,9.8,3.4,7.9,3C6.4,2.6,5.2,2.8,4.3,4.3
		c-0.5,0.9-0.7,1.9-1,2.8c-1.1,2.4-1.7,4.8-1.2,7.5c0.1,0.3,0,0.7,0.4,0.7c0.4,0.1,0.7-0.1,1-0.5c0.2-0.4,0.3-0.8,0.3-1.2
		c0-0.5,0-1,0.2-1.5c0.8-2,1.6-4.1,2.4-6c0.4-0.9,1.1-1.2,2-0.8c1.7,0.6,2.7,1.9,3.6,3.3c0.6,1,0.2,2.6-0.5,3.3
		c-1,1.2-1.9,2.5-2.9,3.8c-1.3,1.8-2.4,3.7-3.8,5.4c-1.5,1.8-2.9,3.7-4.6,5.4c-0.5,0.5-0.5,1.4,0.1,1.9c0.7,0.6,1.4,0.5,1.9-0.3
		c0.4-0.6,0.8-1.2,1.2-1.7c2-2.7,2.1-2.8,4.6-1.4c0.1,0.1,0.2,0.2,0.4,0.2c1.6,1,3.3,1.7,5.2,1.6c1.4-0.1,2.2-0.7,2.8-1.9
		c0.8-1.7,1.2-3.5,1.1-5.4c-0.1-2.1-0.4-4.2-1.3-6.2c-0.6-1.3-1.4-2.6-0.4-4.1C16.6,8.3,17.1,7.3,17.6,6.3z M13.4,23.7
		c-0.7,0.6-1.4,0.7-2.4,0.2c-1-0.5-1.9-1.2-3-1.7c-0.5-0.2-0.5-0.6-0.2-1c0.7-1.2,1.6-2.1,2.3-3.1c1.1-1.5,2.2-3,3.3-4.5
		c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.3-0.2,0.5,0.1c0.3,0.8,0.7,1.5,0.5,2.5c0.2,1.2,0.4,2.6,0.4,3.9C15.3,21.2,14.6,22.6,13.4,23.7
		z"/>
	<path d="M86,19.6c0-0.1-0.2-0.2-0.4-0.2c-1-0.3-1.6,0.1-1.7,1.2c0,0.3,0.1,0.7-0.2,1c-1,1.1-2,2.1-3.5,2.5
		c-0.7,0.2-1.2,0.1-1.7-0.3c-0.4-0.3-0.3-0.4,0-0.7c0.6-0.6,1.3-1.2,1.9-1.8c0.9-1,1.5-2,1.4-3.4c-0.3-2.6-2-3.4-4.1-3.2
		c-1.6,0.1-2.8,1.4-2.5,3c0.1,0.4-0.1,0.7-0.2,1c-1.2,1.9-2.4,3.9-3.6,5.9c-0.4,0.7-0.2,1.5,0.6,2c0.5,0.3,1.5,0.2,1.7-0.3
		c0.3-0.7,0.7-1.1,1.5-0.9c0.1,0,0.3,0,0.4-0.1c0.4-0.4,0.6-0.1,1,0.1c1.1,0.8,2.2,1.6,3.8,1.5c0.7,0.1,1.4-0.1,2-0.3
		c1.7-0.5,2.6-1.8,3.5-3.2c0.4-0.7,0.7-1.4,1-2.2C87.1,20.4,85.7,20.4,86,19.6z M78,17.4c0-0.3,0.4-0.2,0.6-0.2
		c0.3,0,0.3,0.3,0.2,0.5c-0.3,1-0.5,1.9-1.1,2.8C77.5,19.4,78,18.4,78,17.4z"/>
	<path d="M118.5,16.6c-0.2-0.8-0.8-1.6-1.6-1.9c-1-0.5-2.2-0.2-3.3-0.1c-1.5,0.2-2.7,1.2-4,1.9c-1.3,0.8-1.4,1.5-0.8,2.8
		c0.1,0.2,0.3,0.5,0.4,0.7c0.5,1.1,1.6,1.7,2.2,2.7c0.4,0.5,0.2,0.9-0.2,1.1c-1.6,0.7-3.2,1.1-4.9,0.2c-0.6-0.3-0.6-0.6-0.1-1
		c0.2-0.2,0.5-0.2,0.8-0.4c0.5-0.3,0.9-0.7,0.8-1.4c-0.1-0.7-0.7-0.8-1.3-0.7c-1,0.1-1.9,0.6-2.7,1c-0.5,0.2-0.8,0.9-0.6,1.5
		c0.5,1.3,1.3,2.4,2.5,3c1.3,0.6,2.6,1,4.2,0.9c1.4-0.1,2.7-0.3,3.9-1c0.5-0.3,1.1-1.7,0.9-2.1c-0.5-0.9-0.7-2-1.6-2.6
		c-0.2-0.1-0.2-0.4-0.3-0.5c0.5-0.1,0.9,0,1.4-0.2c1.2-0.5,2.2-1.3,3.3-2C118,18,118.6,17.3,118.5,16.6z M111.9,19.5
		c-0.9-1.1-0.8-1.3,0.4-1.8c0.3-0.1,0.6-0.3,0.9-0.5c0.3-0.2,0.6-0.3,1-0.4C113.4,17.8,111.9,18,111.9,19.5z"/>
	<path d="M178.2,16.9c-1.2-0.7-2.6-0.8-4,0.1c-1.5,1-2.7,2-2.6,4c0.3-0.1,0.3-0.5,0.7-0.5c0.2,0.4-0.1,0.8-0.2,1.1
		c-0.2,0.5-0.6,1.1-0.8,1.6c-0.5,1.5-0.2,2.5,1,3.4c0.7,0.5,1.5,0.2,2.3,0.2c1.4,0,2.5-0.8,3.1-1.7c0.8-1.1,1.7-2.3,2.1-3.8
		C180.2,19.8,179.4,17.7,178.2,16.9z M177.4,20.5c-0.6,1.4-1.7,2.5-3.1,3.4c-0.1-0.6,0.4-0.9,0.6-1.2c0.7-1.2,0.5-2.2-0.9-2.6
		c0.6-0.5,1.1-1.2,1.8-1.5c0.5-0.2,1,0,1.3,0.4C177.5,19.5,177.6,20,177.4,20.5z"/>
</g>
</svg>
  );
}

export default Logo;