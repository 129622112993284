import React from 'react'
import PropTypes from 'prop-types'

const icons = {
  facebook: {
    shape: (
      <path d="M483.7,0H28.3C12.7,0,0,12.6,0,28.3v455.5C0,499.3,12.7,512,28.3,512h245.2V313.7h-66.7v-77.3h66.7v-57
		c0-66.1,40.4-102.1,99.4-102.1c28.3,0,52.5,2.1,59.6,3v69.1l-40.9,0c-32.1,0-38.3,15.3-38.3,37.6v49.3h76.5l-10,77.3h-66.5V512
		h130.5c15.6,0,28.3-12.7,28.3-28.3V28.2C512,12.6,499.3,0,483.7,0z"/>
    )
  },
  instagram: {
    shape: (
      <path d="m256 2c69 0 77 0 104 2 26 1 45 5 62 12 17 6 31 15 45 29 14 14 22 28 29 45 6 16 11 35 12 61 1 27 1 36 1 105 0 69 0 77-1 104-1 27-6 45-12 62-7 17-15 31-29 45-14 14-28 22-45 29-17 6-36 11-62 12-27 1-36 1-104 1-69 0-78 0-105-1-26-1-45-6-61-12-18-7-32-15-46-29-14-14-22-28-29-45-6-17-11-35-12-62-1-27-1-35-1-104 0-69 0-78 1-105 1-26 6-45 12-61 7-17 15-31 29-45 14-14 28-23 46-29 16-7 35-12 61-12 27-2 36-2 105-2z m0 33c-72 0-81 1-109 2-26 1-40 6-50 9-24 10-41 27-51 51-4 10-8 24-10 50-1 28-1 37-1 109 0 72 0 80 1 108 2 26 6 41 10 50 10 25 26 41 51 51 10 4 24 9 50 10 28 1 37 1 109 1 72 0 80 0 108-1 26-1 40-6 50-10 24-10 41-26 51-51 4-9 9-24 10-50 1-28 1-36 1-108 0-72 0-81-1-109-1-26-6-40-10-50-10-24-26-41-51-51-10-3-24-8-50-9-28-1-36-2-108-2z m0 322c56 0 101-45 101-101 0-56-45-102-101-102-56 0-102 46-102 102 0 56 46 101 102 101z m0-231c72 0 130 58 130 130 0 72-58 130-130 130-72 0-130-58-130-130 0-72 58-130 130-130z m166-6c0 18-14 30-32 30-17 0-30-13-30-30 0-16 14-31 30-31 17 0 32 14 32 31z"/>
    )
  }
}

const SVG = ({ icon }) => {
  return (
    <svg viewBox="0 0 512 512">
      {icons[icon].shape}
    </svg>
  )
}

SVG.propTypes = {
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
}

export default SVG
